import React from 'react';

function AnswerOption(props) {

  return (
    <li className="answerOption">
      <input id="horizontal-list"
        type="radio"
        id={props.answerType}
        value={props.index}
        checked={(props.selectedAnswer === props.index)}
        onClick={props.onAnswerSelected}
      />{props.answerContent}
    </li>
  );

}

export default AnswerOption;