import React from 'react'
import logo from '../logo.png'
import { updateMins } from './RxjsServices';

class Thanks extends React.Component {

    constructor(props){
        super(props);
    }

    componentWillMount() {
        
        localStorage.removeItem('min');
        localStorage.removeItem('sec');
        localStorage.removeItem('code');
        localStorage.removeItem('exam_type');
        localStorage.removeItem('course_id');
        localStorage.removeItem('student_id');
        localStorage.removeItem('user_name');
    }

    componentDidMount() {
        updateMins.complete();
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
        localStorage.removeItem('min');
        localStorage.removeItem('sec');
        localStorage.removeItem('code');
        localStorage.removeItem('exam_type');
        localStorage.removeItem('course_id');
        localStorage.removeItem('student_id');
        localStorage.removeItem('user_name');
    }

    render () {
        return (
            <div className="App">
                <div id="formWrapper">
                    <div id="form">
                        <div className="logo">
                        <img src={logo} alt={"logo"}/> 
                        </div>
                        <p>{localStorage.getItem('message')}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Thanks;