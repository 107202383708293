import React from 'react';
import Question from '../components/Question';
import QuestionCount from '../components/QuestionCount';
import AnswerOption from '../components/AnswerOption';

function Quiz(props) {

  function renderAnswerOptions(key,index) {
    console.log('renderAnswerOptions',key,index);
    return (
      <AnswerOption
        index ={index}
        key={key[0]}
        answerContent={key[0]}
        answerType={key[0]}
        answer={props.answer}
        questionId={props.questionId}
        selectedAnswer={props.selectedAnswer}
        onAnswerSelected={props.onAnswerSelected}
      />
    );
  }

  return (
    <div className="container">
      <div key={props.questionId} className="quiz-story">
        <QuestionCount counter={props.counter} viewreults={props.viewreults}
          counter={props.questionId}
          total={props.questionTotal}
        />
        <Question  content={props.question} />
        <ul className="answerOptions">
          {props.answerOptions.map(renderAnswerOptions)}
        </ul>
        <div className="bottom-footer" >
          {props.counter > 0 ? (<button className="Previous-btn" onClick={props.setPreviousQuestion} >Previous</button>) : (<div></div>)}

          {props.counter < props.questionTotal-1? (<button className="next-btn" onClick={props.setNextQuestion} >Next</button>) : (<div><button className="next-btn" onClick={props.viewreults} >Submit</button></div>)}
        </div>
      </div>
      </div>
  );
}


export default Quiz;