import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom';

class Result extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        let userId = localStorage.getItem("student_id")
        let courseId = localStorage.getItem("course_id")
        console.log('m course id', courseId, userId);
        localStorage.removeItem('min');
        localStorage.removeItem('sec');
        let correctAnswers = 0
        let quizResult = this.props.quizResult
        let answers = this.props.answers
        let x = []
        for ( let i = 0; i < quizResult.length; i++ ) {
            let z = {}
            z["student_id"] =  userId 
            z["course_id"] = courseId
            z["question"] = quizResult[i].question
            z["question_id"] = quizResult[i].question_id
            if (answers[i]!=null) {
                z["student_answer"] = quizResult[i].answers[answers[i]][0]
            } else {
                z["student_answer"] = ''
            }
            console.log('aman',quizResult[i].answers);
            console.log(quizResult[i].correct_answer);
            z["correct_answer"] = quizResult[i].answers[quizResult[i].correct_answer-1][0]
            x.push(z)
            if ((quizResult[i].correct_answer-1 ==  answers[i])) {
                correctAnswers = correctAnswers + 1
            }
        }
        const data = new FormData()
        data.append('student_id', userId)
        data.append('course_id', courseId)
        data.append('correct_answers', correctAnswers)
        data.append('total_questions', quizResult.length)
        axios({
            method: 'post',
            url: 'https://tnp.ideafoundation.in/api/public/api/updateScore',
            data: data
        })
        .then( response => {
            axios({
                method: 'post',
                url: 'https://tnp.ideafoundation.in/api/public/api/objectiveExamResult',
                data: x,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then( response => {
                localStorage.clear();
                console.log('success')
            })
            this.props.history.push('/thanks')
        })
    }

    render () {
        return (
            <div>
                
            </div>
        )
    }
}

export default withRouter(Result);