import React, {useEffect} from 'react';
import { submitTest } from './RxjsServices';

const QuestionCount = (props) => {

  useEffect(() => {
    if (props.counter === props.total) {
      submitTest.next({submit:true})
    }
})

  return (
    <div className="questionCount">
      Question <span>{props.counter}</span> of <span>{props.total}</span>
    </div>
  );

}

export default QuestionCount;