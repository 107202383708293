import React from 'react';
import MathJax from 'react-mathjax-preview';

function Question(props) {
    var courseId = localStorage.getItem("course_id");
	if (courseId == 17 || courseId == 18) {
		var questionOptions = props.content.split("#");
	}
	
	function previewFormula(key,index) {
		console.log('previewFormula',key);
		console.log('previewFormula',index);
		return (
			<MathJax math={key} />
		);
	}

	return (
		<div>
			<div className="question">{courseId == 17 || courseId == 18 ? (questionOptions).map(previewFormula) : <div dangerouslySetInnerHTML={{__html: props.content}} />}</div>
		</div>
	);
}

export default Question;