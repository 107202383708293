import React,{ useState, useEffect } from 'react'
import { updateMins } from './RxjsServices';


export const Timer = () => {

  const [secs, setsecs] = useState( 0 );
  const [mins, setMins] = useState( 0 );
  useEffect( () => {
    let isMounted = true;
    let timerSubscription = updateMins.subscribe((data)=>{
        console.log('am still calling');
        if(isMounted){
            if (data.secs) {
                setsecs(data.secs)
            }
            if (data.mins && isMounted) {
                setMins(data.mins)
            }
        }
        else{
            localStorage.clear();
        }
    });
    return ()=>{
        isMounted = false
        //timerSubscription();
        updateMins.complete();
        console.log(timerSubscription);
        timerSubscription.unsubscribe();
        localStorage.clear();
        //updateMins.unsubscribe();
    }
  },[])
    

    return (
        <div className="timer">
           Time Left <span>{mins}:{secs}</span> 
        </div>
    ); 
}