import React, {useState, useEffect} from 'react';
import { updateMins, submitTest } from './RxjsServices';
import {Timer} from './Timer'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import logo from '../logo.png'

const Header = () => {
    useEffect(() => {
        let timeInterval = null; 
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
        let examType = localStorage.getItem('exam_type');
        const data = new FormData()
        data.append('course_id', localStorage.getItem("course_id"))
        let link = ''
        if (examType == 'objectiveExam') {
            link = 'https://tnp.ideafoundation.in/api/public/api/getObjectiveExamTime'
        } else {
            link = 'https://tnp.ideafoundation.in/api/public/api/getSubjectiveExamTime'
        }
        axios({
            method: 'post',
            url:link,
            data: data
        })
        .then(response => {
            var examTime = response.data.exam_time
            if (typeof localStorage.getItem("min") !== 'undefined' && typeof localStorage.getItem("sec") !== 'undefined' && localStorage.getItem("min")!= null && localStorage.getItem("sec")!= null ) {
                var min = localStorage.getItem("min");
                var sec = localStorage.getItem("sec");
            } else{
                console.log('min',examTime)
                var min = "0"+examTime ;
                var sec = "0"+0;
            }
            var time;
            console.log(min, sec)
            timeInterval = setInterval(function() {

                localStorage.setItem("min", min);
                localStorage.setItem("sec", sec);
                updateMins.next({mins:min, secs:sec})
                time = min +" : "+ sec;
                if (sec == 0) {
                    if (min !=0) {
                        min--;
                        sec = 59;
                        if (min < 10) {
                            min = "0"+ min;
                        }
                    }
                } else {
                    sec--;
                    if (sec < 10) {
                        sec= "0" + sec;
                    }
                }
            },1000);
        })
        .catch(error => {
            console.log(error)
        })
        return () => {
            if(timeInterval){
                clearInterval(timeInterval);
            }
            
        }
        
    });

  return (
    <div className="App">
        <div className="App-header">
            <div className="headerfront">
                <div className="container" style={{ marginTop: "0px", marginBottom: "0px"}}>
                    <div className="headerTopMain">
                        <div className="logoLeft">
                            <img src={logo} alt={"logo"}/>
                        </div>
                        <div className="userNameRight">
                            <div className="" style={{ color: "#000", fontSize:"18px", marginTop: "10px"}}>
                                Hello <strong>{localStorage.getItem('user_name')}</strong>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <div className="subHeader">
                <div className="container">
                    <div className="row">                        
                        
                        <div className="col-sm courseName" style={{ color: "#fff", fontSize:"18px",}}>
                            <strong>{localStorage.getItem('course_name')}</strong>
                        </div>
                        <div className="col-sm text-right" style={{ color: "#fff", fontSize:"18px", width:"200px"}}>
                        <Timer/>
                        </div>
                    </div>
                </div>
            </div> 
          </div>
    </div>
  );
}
  
export default Header;