import React from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom'
import Test from './components/Test'
import Code from './components/Code'
import Header from './components/Header'
import Thanks from './components/Thanks'
import SubjectiveTest from './components/SubjectiveTest'

const Routes = () => (
    <HashRouter> 
        <Switch>
            <Route exact path="/:exam/:id/:courseid" render={(props) => <Code {...props} />}/>
            <Route path="/thanks" component={Thanks} />
            <Route component={DefaultContainer}/>
        </Switch>
    </HashRouter>
)


const DefaultContainer = () => (
    <div>
        <Header />
        <Route exact path="/objective_test" render={(props) => <Test {...props} />}/>
        <Route exact path="/subjective_test" render={(props) => <SubjectiveTest {...props} />}/>
    </div>
)

 export default Routes;