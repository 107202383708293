import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom';

class SubjectiveResult extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        let userId = localStorage.getItem("student_id");
        let courseId = localStorage.getItem("course_id");
        localStorage.removeItem('min');
        localStorage.removeItem('sec');
        let allSubjectiveQuestion = this.props.questions;
        let answers = this.props.answers;
        let subjectiveTestDetail = [];

        for (let i=0; i<allSubjectiveQuestion.length; i++) {
            let subjective = {}
            subjective["student_id"] =  userId;
            subjective["course_id"] = courseId;
            subjective["question"] = allSubjectiveQuestion[i].question;
            subjective["answer"] = answers[i];
            subjectiveTestDetail.push(subjective);
        }

        axios({
            method: 'post',
            url: 'https://tnp.ideafoundation.in/api/public/api/subjectiveExamResult',
            data: subjectiveTestDetail,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then( response => {
            localStorage.clear();
			console.log('success test');
        });

        this.props.history.push('/thanks')
    }

    render () {
        return (
            <div></div>
        )
    }
}

export default withRouter(SubjectiveResult);