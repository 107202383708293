import React from 'react';
import Question from '../components/Question';
import QuestionCount from '../components/QuestionCount';

function SubjectiveView(props) {
    return (
        <div className="container">
            <div key={props.questionId} className="quiz-story">
                <QuestionCount counter={props.counter} viewreults={props.viewreults} counter={props.questionId} total={props.questionTotal} />
                <Question content={props.question} />
                <textarea name="answer" rows="15" className="objective-answer" value={props.subAnswer} onChange={props.setAnswer} onKeyDown={props.setDisableCopyPaste} onMouseDown={props.setDisableCopyPaste} />
                <div className="bottom-footer" >
                    {props.counter > 0 ? (<button className="Previous-btn" onClick={props.setPreviousQuestion} >Previous</button>) : (<div></div>)}

                    {props.counter < props.questionTotal-1? (<button className="next-btn" onClick={props.setNextQuestion} >Next</button>) : (<div><button className="next-btn" onClick={props.viewreults} >Submit</button></div>)}
                </div>
            </div>
        </div>
    );
}

export default SubjectiveView;