
import React, { Component } from 'react';
// import this.state.quizQuestions from '../api/questions';
import Quiz from './Quiz';
import Result from './Result';
import '../index.css';
import '../App.css';
import Header from './Header';
import axios from 'axios'
import { submitTest, updateMins } from './RxjsServices';
import { withRouter } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';

class Test extends Component {

  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      questionId: 1,
      question: '',
      answerOptions: [],
      allQuestions : [],
      answer: '',
      selectedAnswers : {},
      result: '',
      quizQuestions: []
    };
    
    this.setNextQuestion = this.setNextQuestion.bind(this);
    this.setPreviousQuestion = this.setPreviousQuestion.bind(this);
    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    this.viewreults = this.viewreults.bind(this);
  }

  handleAnswerSelected(e){
    var _self = this;
    var obj = _self.state.selectedAnswers;
    console.log('my obj', obj)
    var index = parseInt(e.target.value);
    console.log("for selected question number " + (_self.state.counter + 1) +  " answer is " + index + " selected");
    var Qindex = (_self.state.counter )
    // create map and store all selecred answers with quiz Questions
    obj[Qindex] = index;
    _self.setState({selectedAnswers : obj})
  }   

  componentWillMount() {
      let parts = window.location.pathname.split("/");
      let courseId = parts[parts.length - 1];
      const data = new FormData()
      data.append('course_id', localStorage.getItem("course_id"))
      axios({
          method: 'post',
          url: 'https://tnp.ideafoundation.in/api/public/api/objectiveQuestion',
          data: data
      })
      .then(response => {
        console.log(response.data)
        this.setState({
          quizQuestions: response.data,
          question: response.data[0].question,
          answerOptions : response.data[0].answers,
          allQuestions : response.data
        });
      })
      .catch(error => {
          console.log(error)
      })
  }

  componentDidUpdate() {
    setTimeout(()=>{
      if (typeof localStorage.getItem("min") !== 'undefined' && typeof localStorage.getItem("sec") !== 'undefined' && localStorage.getItem("min") == '00' && localStorage.getItem("sec") == '00' ) {
        this.setState({result : true})
        localStorage.removeItem('code');
        localStorage.setItem('message', 'Oops looks like you were timed out as max time limit reached. However Your test has been submitted. We will get back to you shortly.');
      } else {
        console.log('yes')
        this.setState({test:false})
      }
    }, 3000)
  }

  setNextQuestion() {
    const counter = this.state.counter + 1;
    const questionId = this.state.questionId + 1;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: this.state.quizQuestions[counter].question,
      answerOptions: this.state.quizQuestions[counter].answers,
      answer: ''
    });
  }
  
  setPreviousQuestion() {
    const counter = this.state.counter - 1;
    const questionId = this.state.questionId - 1;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: this.state.quizQuestions[counter].question,
      answerOptions: this.state.quizQuestions[counter].answers,
      answer: ''
    });
  }

  getResults() {
    const answersCount = this.state.answersCount;
    const answersCountKeys = Object.keys(answersCount);
    const answersCountValues = answersCountKeys.map((key) => answersCount[key]);
    const maxAnswerCount = Math.max.apply(null, answersCountValues);
    return answersCountKeys.filter((key) => answersCount[key] === maxAnswerCount);
  }

  setResults(result) {
    if (result.length === 1) {
      this.setState({ result: result[0] });
    } else {
      this.setState({ result: 'Undetermined' });
    }
  }

  renderQuiz() {
    return (
      <Quiz viewreults={this.viewreults}
        setNextQuestion={this.setNextQuestion}
        counter={this.state.counter}
        setPreviousQuestion={this.setPreviousQuestion}
        answer={this.state.answer}
        selectedAnswer = {this.state.selectedAnswers[this.state.counter]}
        answerOptions={this.state.answerOptions}
        questionId={this.state.questionId}
        question={this.state.question}
        questionTotal={this.state.quizQuestions.length}
        onAnswerSelected = {this.handleAnswerSelected}
      />
    );
  }
 
  renderResult() {
    return (
      <Result quizResult={this.state.allQuestions} answers={this.state.selectedAnswers} />
    );
  }

  viewreults(e){
    console.log('m in');
    confirmAlert({
      message: 'Are you sure you want to submit? This action can not be undone.',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                e.preventDefault()
                localStorage.setItem('message', 'Thank you for submitting your test successfully. We will get back to you shortly.');
                
                //updateMins.unsubscribe();
                localStorage.removeItem('min');
                localStorage.removeItem('sec');
                this.setState({result : true})
              }
          },
          {
              label: 'No',
          }
      ]
    });
  }

  render() {
    return (
      <div>
        {this.state.result ? this.renderResult() : this.renderQuiz()}
      </div>

    );
  }
}

export default withRouter(Test);