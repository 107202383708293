import React from 'react'
import Routes from './Routes.js';
import './App.css';
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";


const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

const App = () => (
  <Provider template={AlertTemplate} {...options}>
    <Routes />
  </Provider>
);
 
export default App


