import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert'
import { useHistory, useLocation} from 'react-router-dom'
import logo from '../logo.png'

const Code = () => {
    
    const alert = useAlert()
    const history = useHistory();
    const hash  = useLocation();
    const [code, xCode] = useState(0)
    const [name, setName] = useState('')

    useEffect(() => {
        localStorage.clear();
        document.addEventListener('contextmenu', function(e) {
            e.preventDefault();
        });
        document.onkeydown = function(e) {
            if(e.keyCode == 123) {
               return false;
            }
            if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
               return false;
            }
            if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
               return false;
            }
            if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
               return false;
            }
            if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
               return false;
            }
        }
        if (code == 0) {
            let parts = hash.pathname.split("/");
            console.log(parts);
            let examType = parts[1];
            let userId = parts[parts.length - 2];
            let courseId = parts[parts.length - 1];
            const data = new FormData()
            data.append('student_id', userId)
            data.append('course_id', courseId)
            data.append('exam_type',examType);
            axios({
                method: 'post',
                url: 'https://tnp.ideafoundation.in/api/public/api/sendVerificationCode',
                data: data
            })
            .then(response => {
                setName(response.data.name)
            })
            .catch(error => {
                if (error.response.data.error != '') {
                    alert.error(error.response.data.error);
                } 
                
            })
        }
    })

    const setCode = (e) => {
        e.preventDefault();
        xCode(e.target.value)
    }

    const startTest = (e) => {
        e.preventDefault();
        let parts = hash.pathname.split("/");
        let userId = parts[parts.length - 2];
        let courseId = parts[parts.length - 1];
        let examType = parts[parts.length - 3];
        const data = new FormData()
        data.append('student_id', userId);
        data.append('course_id', courseId);
        data.append('code', code);
        data.append('exam_type', examType);
        axios({
            method: 'post',
            url: 'https://tnp.ideafoundation.in/api/public/api/verifyExamCode',
            data: data
        })
        .then(response => {
            localStorage.setItem("code", true);
            localStorage.setItem("exam_type", examType);
            localStorage.setItem("student_id", userId);
            localStorage.setItem("course_id", courseId);
            localStorage.setItem("user_name", name);
            localStorage.setItem("course_name", response.data.course_name);
            if (examType == 'objectiveExam') {
                history.push("/objective_test");
            } else {
                history.push("/subjective_test");
            }
        })
        .catch(error => {
            console.log(error)
            alert.error("Wrong Credentials!!");
        })
    }

    return (
        
        <div className="App">
            <div id="formWrapper">
                <div id="form">
                    <div className="logo">
                     <img src={logo} alt={"logo"}/> 
                    </div><br />
                    <h4 style={{ color: "#3f3e3e", fontSize:"18px", marginTop: "10px", textAlign: "center" }}>
                        Hello <strong style={{ color: "#ff6400" }}>{name}</strong>
                    </h4>
                    <p>Please Add Code Received on your Email</p>
                    <form onSubmit={startTest}>
                        <div className="form-item">
                            <input type="text" name="setcode" className="form-style" placeholder="Enter Code" onChange={setCode}/>
                        </div> <br />
                        <div className="form-item">
                            <button className="login pull-right" type="submit" onClick={startTest} >Start Test</button>
                            <div className="clear-fix"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}


export default Code;
