import React, {Component} from 'react';
import axios from 'axios';
import SubjectiveView from './SubjectiveView';
import { confirmAlert } from 'react-confirm-alert';
import { withRouter } from 'react-router';
import SubjectiveResult from './SubjectiveResult';

class SubjectiveTest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            counter: 0,
            questionId: 1,
            allQuestions : [],
            allObjectiveQuestions: [],
            subjectiveAnswer: {},
            result: false
        };

        this.setNextQuestion = this.setNextQuestion.bind(this);
        this.setPreviousQuestion = this.setPreviousQuestion.bind(this);
        this.setAnswer = this.setAnswer.bind(this);
        this.viewreults = this.viewreults.bind(this);
        this.setDisableCopyPaste = this.setDisableCopyPaste.bind(this);
    }

    componentWillMount() {
        const data = new FormData()
        data.append('course_id', localStorage.getItem("course_id"))
        axios({
            method: 'post',
            url: 'https://tnp.ideafoundation.in/api/public/api/subjectiveQuestion',
            data: data
        }).then(response => {
            this.setState({
                allObjectiveQuestions: response.data,
                question: response.data[0].question,
                allQuestions : response.data
            });
        })
    }

    componentDidUpdate() {
        setTimeout(()=>{
            if (typeof localStorage.getItem("min") !== 'undefined' && typeof localStorage.getItem("sec") !== 'undefined' && localStorage.getItem("min") == '00' && localStorage.getItem("sec") == '00' ) {
                localStorage.setItem('message', 'Oops looks like you were timed out as max time limit reached. However Your test has been submitted. We will get back to you shortly.');
				localStorage.removeItem('code');
				this.setState({result : true})
            } else {
				this.setState({result : false})
			}
        }, 3000)
    }

    setNextQuestion() {
        const counter = this.state.counter + 1;
        const questionId = this.state.questionId + 1;

        this.setState({
            counter: counter,
            questionId: questionId,
            question: this.state.allObjectiveQuestions[counter].question
        });
    }

    setPreviousQuestion() {
        const counter = this.state.counter - 1;
        const questionId = this.state.questionId - 1;

        this.setState({
            counter: counter,
            questionId: questionId,
            question: this.state.allObjectiveQuestions[counter].question
        });
    }

    setAnswer(event) {
        var questionIndex = this.state.counter;
        var obj = this.state.subjectiveAnswer;
        var subjAnswer = event.target.value;
        obj[questionIndex] = subjAnswer;
        this.setState({
            subjectiveAnswer: obj
        });
    }

    setDisableCopyPaste(event) {
        var controlKeyCode = event.keyCode;
        var rightClickMouseCode = event.button;
        if (controlKeyCode == 17 || rightClickMouseCode == 2) {
            alert("Cntrl key/ Right Click Option disabled");
            return false;
        }
    }

    renderSubjective() {
        return (
            <SubjectiveView viewreults={this.viewreults}
                setNextQuestion={this.setNextQuestion}
                counter={this.state.counter}
                setPreviousQuestion={this.setPreviousQuestion}
                questionId={this.state.questionId}
                question={this.state.question}
                questionTotal={this.state.allObjectiveQuestions.length}
                setAnswer={this.setAnswer}
                subAnswer={this.state.subjectiveAnswer[this.state.counter]}
                setDisableCopyPaste={this.setDisableCopyPaste}
            />
        );
    }

    renderResult() {
        return (
            <SubjectiveResult questions={this.state.allQuestions} answers={this.state.subjectiveAnswer} />
        );
    }

    viewreults(e) {
        confirmAlert({
            message: 'Are you sure you want to submit? This action can not be undone.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        e.preventDefault()
                        localStorage.setItem('message', 'Thank you for submitting your test successfully. We will get back to you shortly.');
                        localStorage.removeItem('min');
                        localStorage.removeItem('sec');
                        this.setState({result : true})
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    render() {
        return (
            <div>
                {this.state.result ? this.renderResult() : this.renderSubjective()}
            </div>
        );
    }
}

export default withRouter(SubjectiveTest);